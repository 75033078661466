@font-face {
	font-family: "Inter-SemiBold";
	src: url("../../../..//assets/fonts/earthyworthy/Inter-SemiBold.ttf") format('truetype');
}

@font-face {
	font-family: "proxima-nova";
	src: url("../../../fonts/earthyworthy/Proxima Nova Font.otf") format('opentype');
}

.modal-backdrop{
	position:relative; 
	z-index:1040!important;
}